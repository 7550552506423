import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ measure }) => {
  return (
    <div className={styles.measure}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{measure.title}</h2>

        <div className={styles.steps}>
          {measure.steps &&
            measure.steps.map((step, index) => (
              <div key={step._key} className={styles.step}>
                <div className={styles.stepLeft}>
                  <img
                    className={styles.stepImage}
                    {...srcSetProps(sanityImageUrl(step?.image))}
                    alt={step.image?.caption}
                  />
                </div>
                <div className={styles.stepRight}>
                  <p className={styles.stepTitle}>{step.title}</p>
                  <p className={styles.stepInfo}>{step.information}</p>
                </div>
                <div className={styles.stepIndex}>{index + 1}</div>
              </div>
            ))}
        </div>

        <a href={measure.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
          {measure.buttonText}
        </a>
      </div>
    </div>
  )
}
